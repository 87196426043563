import {string,object,ref, number} from 'yup';

export const CURRENT_PAGE = 1;
export const PER_PAGE = 10;


export const STAFF_SCHEMA =(isUpdate) => object().shape({
    name: string().required("Name is required"),
    mobile : number().transform((value) => Number.isNaN(value) ? null : value )
    .nullable().min(10),
    email: string().email().required('Email is required'),
    password: isUpdate? string() : string().required('Password is required'),
    r_password: isUpdate? string() : string()
    .oneOf([ref('password'), null], 'Passwords must match'),
    role: string()
})

export const ROLE_PERMISSIONS =  {
    can_client_chat_access: 'Chat access',
    can_client_manage_form: 'Manage form',
    can_client_shipping_bill_upload: 'Shipping bill',
    can_client_upload_files: 'Upload files',
    client_manager_role : 'Manager role',
    can_view_client_staff: 'View staff',
    can_create_client_staff: 'Create staff',
    add_clientuser: 'Add Client',
}

export const PAGE_PERMISSIONS =  {
    admin: ['client_admin_role'],
    manager: ['client_manager_role', 'can_view_client_staff', 'can_create_client_staff', 'add_clientuser'],
    staff: ['can_client_shipping_bill_upload', 'can_client_upload_files', 'can_client_manage_form', 'can_client_chat_access']
}

export const CRUD_PERMISSIONS = {
    sheet:{
        client_manager_role: ['client_manager_role']
    }
}

export const RESTRICT_ASSIGN_ROLES = [2]