import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { LIST_ROLES, CREATE_STAFF, GET_STAFF_LIST, STAFF_STATUS_UPDATE, GET_STAFF_INFO, UPDATE_STAFF } from '../constants/services'
import { ROUTES, CURRENT_PAGE, PER_PAGE } from '../constants/staff'
import axiosInstance from '../services/instance'

export const getRoles = createAsyncThunk('users/getRoles', async (data = {}, thunkAPI) => {
    try {
      const response = await axiosInstance.get(LIST_ROLES)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response ? error.response.data.message : 'Roles Fetching failed')
    }
  })

export const getStaffList = createAsyncThunk(
    'staff/getStaffList', async ({page, search}, thunkAPI) => {
    const data = {
        page: CURRENT_PAGE,
        perpage: PER_PAGE
    }

    if (page ) data.page  = page
    if (search) data.q = search
    try{
        const response = await axiosInstance.post(GET_STAFF_LIST, data)
        return response.data
    } catch(err) {
        return thunkAPI.rejectWithValue(err?.response?.data) 
    }
})

export const updateStatus = createAsyncThunk(
    'staff/updateStatus',
    async ({ id, active, page }, thunkAPI) => {
        let current_page  = page ? page: CURRENT_PAGE
        try{
            const response = await axiosInstance.post(`${STAFF_STATUS_UPDATE}/${id}`, { active })
            return response.data
        } catch (error) {
            // Log the error for debugging

            // Return the error message or a custom error
            return thunkAPI.rejectWithValue(error.response ? error.response.data.message : 'Failed to update Staff status');
        }
    },
)

export const getStaffInfo = createAsyncThunk(
    'staff/getStaffInfo',
    async (staff_id, thunkAPI) => {
        try{
            const response = await axiosInstance.get(`${GET_STAFF_INFO}/${staff_id}`)
            return response.data;
        } catch (error) {
            // Log the error for debugging

            // Return the error message or a custom error
            return thunkAPI.rejectWithValue(error.response ? error.response.data.message : 'Profile Fetching failed');
        }
    },
)


export const createStaff = createAsyncThunk(
    'staff/createStaff',
    async ({ name, mobile, email, password, role }, thunkAPI) => {
        try{
            const response = await axiosInstance.post(CREATE_STAFF, { name, email, password, role_id:role, phone:mobile })
            return response.data
        } catch (error) {
            // Log the error for debugging

            // Return the error message or a custom error
            return thunkAPI.rejectWithValue(error.response ? error.response.data.message : 'Failed to create Staff');
        }
    },
)

export const updateStaff = createAsyncThunk(
    'staff/updateStaff',
    async ({data, id}, thunkAPI) => {
        try{
            const response = await axiosInstance.post(`${UPDATE_STAFF}/${id}`, data)
            return response.data
        } catch (error) {
            // Log the error for debugging

            // Return the error message or a custom error
            return thunkAPI.rejectWithValue(error.response ? error.response.data.message : 'Failed to update profile');
        }
    },
)



export const staffSlice = createSlice({
    name: 'staff',
    initialState: {
        staffFormData: {
            name: '',
            email: '',
            password: '',
            role: '',
            mobile: '',
        },
        roles: [],
        staffData: [],
        staff: {},
        total_count: 0,
        successMsg: '',
        errorMsg: '',
    },
    reducers: {
        clearMessages: (state) => {
            state.successMsg = ''
            state.errorMsg = ''
        }

    },
    extraReducers: (builder) => {
        builder.addCase(createStaff.fulfilled, (state, action) => {
            state.successMsg = 'Successfully Created Staff'
        })
        builder.addCase(createStaff.rejected, (state, action) => {
            state.errorMsg = 'Failed to Create Staff'
        })

        builder.addCase(getRoles.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getRoles.fulfilled, (state, action) => {
            state.roles = action.payload.data?.roles
        })
        builder.addCase(getRoles.rejected, (state, action) => {
            state.loading = false
            state.errorMsg = action.payload.data ? action.payload.data : 'Failed to update profile'
        })

        builder.addCase(getStaffList.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getStaffList.fulfilled, (state, action) => {
            state.staffData = action.payload.data?.list;
            state.total_count = action.payload.data?.total_count;
        })
        builder.addCase(getStaffList.rejected, (state, action) => {
            state.loading = false
            state.errorMsg = action.payload.data ? action.payload.data : 'Failed to get staff list'
        })

        builder.addCase(updateStatus.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(updateStatus.fulfilled, (state, action) => {
            state.loading = false
            state.successMsg = 'Successfully updated status'
        })
        builder.addCase(updateStatus.rejected, (state, action) => {
            state.loading = false
            state.errorMsg = action.payload.data ? action.payload.data : 'Failed to update status'
        })

        builder.addCase(getStaffInfo.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getStaffInfo.fulfilled, (state, action) => {
            state.loading = false
            state.staff = action.payload.data?.profile
        })
        builder.addCase(getStaffInfo.rejected, (state, action) => {
            state.loading = false
            state.errorMsg = action.payload.data ? action.payload.data : 'Failed to get Staff info'
        })
        
        builder.addCase(updateStaff.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(updateStaff.fulfilled, (state, action) => {
            state.loading = false
            state.successMsg = 'Successfully updated Staff'
        })
        builder.addCase(updateStaff.rejected, (state, action) => {
            state.loading = false
            state.errorMsg = action.payload.data ? action.payload.data : 'Failed to update profile'
        })

    }

})

export const { clearMessages } = staffSlice.actions

export default staffSlice.reducer