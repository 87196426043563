import Base from '../../common/Base';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChatPopup from '../../common/ChatPopup';
import { Row, Col, Accordion, Card } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import gstr1 from '../../../assets/images/gstr-1.svg';
import { getFacilitationName } from '../../../slices/forms/facilitation';
// import PaginationComponent from "../common/Pagination";


const ExportImportFacilitation = () => {
  const dispatch = useDispatch();
  // const [current_page, setCurrentPage] = useState(1);
  const { facilitationData,total_count, loading, error } = useSelector((state) => state.facilitation);

  // const handlePagination = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  //   dispatch(
  //     getFacilitationName({ page: pageNumber, perpage: 25 })
  //   );
  // };
  useEffect(() => {
    dispatch(getFacilitationName({}));
  }, [dispatch]);

  return (
    <Base header="ExportImportFacilitation" headerTitle={'Facilitation'}>
      <Row>
        <Col lg={12}>
          <Accordion id="accordionGSTR">
            <Accordion.Item>
              <Accordion.Header> Goods and Service Tax</Accordion.Header>
              <Accordion.Body>
                <Card.Body>
                  <Row>
                    {/* Render dynamic cards */}
                    {!loading && facilitationData.length > 0 ? (
                      facilitationData.map((facilitation, index) => (
                        <Col md={3} sm={6} lg={3} xs={6} key={index}>
                          <Card className="card-stretch card-height text-center pf-h-icon-box pa-box-1">
                            <Card.Body>
                              <div className="mb-2 text-center rounded iq-thumb">
                                <NavLink className="image-popup-vertical-fit">
                                  <img src={gstr1} className="img-fluid" alt="image1" />
                                  <div className="iq-image-overlay"></div>
                                </NavLink>
                              </div>
                              <h6>{facilitation.name}</h6>
                              <p>Created at: {facilitation.created_date.split('T')[0]}</p>
                            </Card.Body>
                          </Card>
                        </Col>
                      ))
                    ) : (
                      <Col>
                        <p>{loading ? 'Loading...' : 'No facilitation data available.'}</p>
                      </Col>
                    )}
                  </Row>
                  {/* <PaginationComponent
                      total_count={total_count}
                      current_page={current_page} 
                      handleOnClick={handlePagination} 
                      per_page={25}
                    /> */}
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
      <ChatPopup chat_type="ExportImportFacilitation" />
    </Base>
  );
};

export default ExportImportFacilitation;
