import React, { useEffect } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import StaffForm from './StaffForm';
import { updateStaff, getStaffInfo, clearMessages } from '../../slices/staff';
import _  from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const EditStaff = () => {
    const dispatch = useDispatch();
    const { staff_id } = useParams()
    const { successMsg, errorMsg, staff, roles } = useSelector(state => state.staff);
    const handleSubmit = (data) => {
        const {
            name,
            email,
            role,
            mobile
        } = data      
        dispatch(updateStaff({data:{name, role_id:role,  phone:mobile}, id: staff_id}))      
    }
    useEffect(() => {
        if (!!errorMsg) {
            toast.error(errorMsg, {
                position: 'top-center'
            })
        }
        if (!!successMsg) {
            toast.success(successMsg, {
                position: 'top-center'
            })
        }
        dispatch(clearMessages());
    }, [successMsg, errorMsg]);

    useEffect(() => {
        if (roles.length) {
            dispatch(getStaffInfo(staff_id))
        }
    }, [roles])
  return (
    <Container>
    <Row>
        <Col xl={12} lg={12}>
            <Card>
                <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                        <h4 className="card-title">Create Staff</h4>
                    </div>
                </Card.Header>
                <Card.Body>
                    <StaffForm submitFunc={handleSubmit} staff={staff} isUpdate/>
                </Card.Body>
            </Card>
        </Col>
    </Row>
    
    </Container>
  )
}

export default EditStaff
