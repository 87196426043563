
const MainHeader = () => {
    return (
        <div className="iq-search-bar device-search">

            <form>
                <div className="input-prepend input-append">
                    <div className="btn-group">
                        <label className="dropdown-toggle searchbox" data-toggle="dropdown">
                            <input className="dropdown-toggle search-query text search-input" type="text" placeholder="Type here to search..." /><span className="search-replace"></span>
                            <a className="search-link" href="#"><i className="ri-search-line"></i></a>
                            <span className="caret"></span>
                        </label>
                        <ul className="dropdown-menu">
                            <li><a href="#"><div className="item"><i className="far fa-file-pdf bg-info"></i>PDFs</div></a></li>
                            <li><a href="#"><div className="item"><i className="far fa-file-alt bg-primary"></i>Documents</div></a></li>
                            <li><a href="#"><div className="item"><i className="far fa-file-excel bg-success"></i>Spreadsheet</div></a></li>
                            <li><a href="#"><div className="item"><i className="far fa-file-powerpoint bg-danger"></i>Presentation</div></a></li>
                            <li><a href="#"><div className="item"><i className="far fa-file-image bg-warning"></i>Photos & Images</div></a></li>
                            <li><a href="#"><div className="item"><i className="far fa-file-video bg-info"></i>Videos</div></a></li>
                        </ul>
                    </div>
                </div>
            </form>
        </div>

    )
}
export default MainHeader