import { createSlice } from '@reduxjs/toolkit'

const currentDate = new Date();
export const commonSlice = createSlice({
    name: 'common',
    initialState: {
        navOpen: true,
        selectedMonth: currentDate.getMonth() + 1,
        selectedYear: currentDate.getFullYear(),
    },
    reducers: {
        setNavOpen: (state, action) => {
            state.navOpen = action.payload
        },
        setSelectedMonthYear: (state, action) => {
            state.selectedMonth = action.payload.month
            state.selectedYear = action.payload.year
        },


    },
})

export const { setNavOpen,setSelectedMonthYear } = commonSlice.actions

export default commonSlice.reducer