import React, { useState, useEffect } from "react";
import { Modal, Button, Card } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch } from "react-redux";
import { createQueries } from "../../../slices/forms/hsnSacReducer";
import { modules } from '../../../constants/common';
import { FaTimesCircle } from "react-icons/fa"; 

const CreateQueryModal = ({ show, onHide, selectedDetails,onCreateQuery }) => {
  const [quillContent, setQuillContent] = useState("");
  const [updatedSelectedDetails, setUpdatedSelectedDetails] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (show) {
      setUpdatedSelectedDetails(selectedDetails || []);
      setQuillContent("");
    }
  }, [show, selectedDetails]);

  const handleRemoveItem = (id) => {
    setUpdatedSelectedDetails((prevDetails) => prevDetails.filter((item) => item.id !== id));
  };


  const handleSubmit = () => {
    const req_ids = updatedSelectedDetails.map((item) => item.id);
    const data = { req_ids, query: quillContent };

    if (onCreateQuery) {
      onCreateQuery(data);
      onHide();
      setQuillContent("");
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered size="lg" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Create Query</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <h5>Selected Items</h5>
          <div className="mb-3 d-flex flex-wrap gap-2">
            {Array.isArray(updatedSelectedDetails) && updatedSelectedDetails.length > 0 ? (
              updatedSelectedDetails.map((item, index) => (
                <Card
                  key={item.id}
                  className="p-2 shadow-sm border rounded"
                  style={{
                    minWidth: "150px",
                    maxWidth: "250px",
                    flex: "1 1 auto",
                    marginBottom: "10px"
                  }}
                >
                  <Card.Body className="d-flex justify-content-between align-items-center p-2">
                    <span>{index + 1}. {item.hsn_number}</span>
                    <FaTimesCircle
                      size={20}
                      style={{ color: "#0d4473", cursor: "pointer" }}
                      onClick={() => handleRemoveItem(item.id)}
                    />
                  </Card.Body>
                </Card>
              ))
            ) : (
              <p>No items selected.</p>
            )}
          </div>
        </div>
        <div className="mt-4">
          <ReactQuill
            theme="snow"
            placeholder="Type your query here..."
            modules={modules}
            value={quillContent}
            onChange={setQuillContent}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={!quillContent.trim() || updatedSelectedDetails.length === 0}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateQueryModal;
