import Base from '../common/Base';
import { Row, Col, Accordion, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import gstr1 from '../../assets/images/gstr-1.svg';
import gstr2 from '../../assets/images/gstr-2.svg';
import ChatPopup from '../common/ChatPopup';

const GstrForms = () => {
  const name = useSelector((state) => state.user.userData.name);
  return (
    <Base>
      <Row>
        <Col lg={12}>
          <h3 className="pa-welcome-notifi">Welcome {name}</h3>
        </Col>
        <Col lg={12}>
          <Accordion id="accordionGSTR">
            <Accordion.Item>
              <Accordion.Header> Goods and service tax</Accordion.Header>
              <Accordion.Body>
                <Card.Body>
                  <Row>
                    <Col md={3} sm={6} lg={3} xs={6}>
                      <Card className="card-stretch card-height text-center pf-h-icon-box pa-box-1">
                        <Card.Body>
                          <div className="mb-2 text-center rounded iq-thumb">
                            <NavLink className="image-popup-vertical-fit">
                              <img src={gstr1} className="img-fluid" alt="image1" />
                              <div className="iq-image-overlay"></div>
                            </NavLink>
                          </div>
                          <h6>
                            {' '}
                            <NavLink to={'/gst-forms/gstr3b'}>GSTR - 3B</NavLink>
                          </h6>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={3} sm={6} lg={3} xs={6}>
                      <Card className="card-stretch card-height text-center pf-h-icon-box pa-box-1">
                        <Card.Body>
                          <div className="mb-2 text-center rounded iq-thumb">
                            <NavLink className="image-popup-vertical-fit">
                              <img src={gstr1} className="img-fluid" alt="image1" />
                              <div className="iq-image-overlay"></div>
                            </NavLink>
                          </div>
                          <h6>
                            {' '}
                            <NavLink to={'/gst-forms/gstr9'}>GSTR - 9</NavLink>
                          </h6>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={3} sm={6} lg={3} xs={6}>
                      <Card className="card-stretch card-height text-center pf-h-icon-box pa-box-1">
                        <Card.Body>
                          <div className="mb-2 text-center rounded iq-thumb">
                            <NavLink className="image-popup-vertical-fit">
                              <img src={gstr1} className="img-fluid" alt="image1" />
                              <div className="iq-image-overlay"></div>
                            </NavLink>
                          </div>
                          <h6>
                            {' '}
                            <NavLink to={'/gst-forms/gstr9c'}>GSTR - 9C</NavLink>
                          </h6>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={3} sm={6} lg={3} xs={6}>
                      <Card className="card-stretch card-height text-center pf-h-icon-box pa-box-1">
                        <Card.Body>
                          <div className="mb-2 text-center rounded iq-thumb">
                            <NavLink to={'/gst-forms/gstr1'} className="image-popup-vertical-fit">
                              <img src={gstr2} className="img-fluid" alt="image1" />
                              <div className="iq-image-overlay"></div>
                            </NavLink>
                          </div>
                          <h6>
                            {' '}
                            <NavLink to={'/gst-forms/gstr1'}>GSTR - 1</NavLink>
                          </h6>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={3} sm={6} lg={3} xs={6}>
                      <Card className="card-stretch card-height text-center pf-h-icon-box pa-box-1">
                        <Card.Body>
                          <div className="mb-2 text-center rounded iq-thumb">
                            <NavLink to={'/gst-forms/refunds'} className="image-popup-vertical-fit">
                              <img src={gstr2} className="img-fluid" alt="image1" />
                              <div className="iq-image-overlay"></div>
                            </NavLink>
                          </div>
                          <h6>
                            {' '}
                            <NavLink to={'/gst-forms/refunds'}>Refunds</NavLink>
                          </h6>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={3} sm={6} lg={3} xs={6}>
                      <Card className="card-stretch card-height text-center pf-h-icon-box pa-box-1">
                        <Card.Body>
                          <div className="mb-2 text-center rounded iq-thumb">
                            <NavLink to={'/gst-forms/litigations'} className="image-popup-vertical-fit">
                              <img src={gstr2} className="img-fluid" alt="image1" />
                              <div className="iq-image-overlay"></div>
                            </NavLink>
                          </div>
                          <h6>
                            {' '}
                            <NavLink to={'/gst-forms/litigations'}>Litigation</NavLink>
                          </h6>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={3} sm={6} lg={3} xs={6}>
                      <Card className="card-stretch card-height text-center pf-h-icon-box pa-box-1">
                        <Card.Body>
                          <div className="mb-2 text-center rounded iq-thumb">
                            <NavLink to={'/gst-forms/upcomingMod'} className="image-popup-vertical-fit">
                              <img src={gstr2} className="img-fluid" alt="image1" />
                              <div className="iq-image-overlay"></div>
                            </NavLink>
                          </div>
                          <h6>
                            {' '}
                            <NavLink to={'/gst-forms/Export-Import-Facilitation'}>Export Import Facilitation</NavLink>
                          </h6>
                        </Card.Body>
                      </Card>
                    </Col>
                    {/* Additional Accordion Items */}
                    {/* ... */}
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
      <ChatPopup chat_type="GENERAL" />
    </Base>
  );
};

export default GstrForms;
