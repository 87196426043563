import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../services/instance'
import { GET_REFUND_FORM_DATA, GET_REFUND_CELL_DATA, ADD_REFUND_COMMENT, UPLOAD_REFUND_FILES,HANDLE_REFUND_SHEET_APPROVAL,CREATE_REFUND_PERIOD, HANDLE_MANAGER_REFUND_SHEET_APPROVAL } from '../../constants/services'

export const fetchFormData = createAsyncThunk(
    'refund/fetchFormData',
    async ({ month, year }, thunkAPI) => {
        const response = await axiosInstance.post(GET_REFUND_FORM_DATA, { month, year })
        return response.data
    },
)

export const getCommentDetails = createAsyncThunk(
    'refund/getCommentDetails',
    async ({ month, year, cell }, thunkAPI) => {
        const response = await axiosInstance.post(GET_REFUND_CELL_DATA, { month, year, cell })
        return response.data
    },
)

export const addCommentDetails = createAsyncThunk(
    'refund/addCommentDetails',
    async ({ folder_id, cell, comment }, thunkAPI) => {
        const response = await axiosInstance.post(ADD_REFUND_COMMENT, { folder_id, cell, comment, status: 2 })
        return response.data
    },
)

export const uploadFiles = createAsyncThunk(
    'refund/uploadFiles',
    async ({ folder_id, files, section_type, notes }, thunkAPI) => {
        const formData = new FormData();
        files.forEach(file => formData.append('files', file));
        //formData.append('files', files);
        formData.append('folder_id', folder_id)
        formData.append('section_type', section_type)
        formData.append('notes', notes)
        const response = await axiosInstance.post(UPLOAD_REFUND_FILES, formData, {
            headers: {
                'Content-Type': 'multipart/form-data', // Set the content type
            }
        })
        return response.data
    },
)

export const handleSheetApproval = createAsyncThunk(
    'refund/handleSheetApproval',
    async ({ folder_id, approve, isManager, sheetId  }, thunkAPI) => {
        try{
            const response = await axiosInstance.post(HANDLE_REFUND_SHEET_APPROVAL, { folder_id, approve });
            return response.data
        } catch (error) {
            // Log the error for debugging
            console.error('Error in handleSheetApproval:', error);

            // Return the error message or a custom error
            return thunkAPI.rejectWithValue(error.response ? error.response.data : 'Something went wrong');
        }
    },
)

export const handleSheetApprovalManager = createAsyncThunk(
    'refund/handleSheetApprovalManager',
    async ({ folder_id, approve, isManager, sheetId }, thunkAPI) => {
        try{
            let response = {};
            response = await axiosInstance.post(`${HANDLE_MANAGER_REFUND_SHEET_APPROVAL}/${sheetId}`, { folder_id, approve });
            return response.data
        } catch (error) {
            // Log the error for debugging
            console.error('Error in handleSheetManagerApproval:', error);
    
            // Return the error message or a custom error
            return thunkAPI.rejectWithValue(error.response ? error.response.data : 'Something went wrong');
        }
    },
)

export const createRefundPeriod = createAsyncThunk(
    'refund/createRefundPeriod',
    async ({ start_date, end_date, folder_name }, thunkAPI) => {
        try{
            const response = await axiosInstance.post(CREATE_REFUND_PERIOD, { start_date, end_date, folder_name })
            return response.data
        } catch (error) {
            // Log the error for debugging
            console.error('Error in createRefundPeriod:', error);

            // Return the error message or a custom error
            return thunkAPI.rejectWithValue(error.response ? error.response.data : 'Something went wrong');
        }
    },
)


const currentDate = new Date();
export const refundSlice = createSlice({
    name: 'refund',
    initialState: {
        excelData: {
            url: '',
            summary: [],
            comments: []
        },
        currentCommentCell: null,
        currentComment: '',
        successMsg: '',
        errorMsg: '',
        uploadFilesSuccess: false,
        billsUploading: false,
        uploadFilesData: {},
        handleSheetApprovalState: {success: false, data: [], error: null},
        createRefundPeriodState: {success: false, data: [], error: null}

    },
    reducers: {
        clearMessages: (state) => {
            state.successMsg = ''
            state.errorMsg = ''
            state.currentCommentCell = ''
            state.currentComment = ''
            state.uploadFilesSuccess = false
            state.billsUploading = false
            state.uploadFilesData = {},
            state.handleSheetApprovalState = {success: false, data: [], error: null},
            state.createRefundPeriodState = {success: false, data: [], error: null}
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFormData.fulfilled, (state, action) => {
            const { sheet, summery, all_comments } = action.payload.data;
            if (sheet && sheet.url) {
                state.excelData.url = sheet.url
            } else {
                state.excelData.url = ''
            }
            state.excelData.summary = summery
            state.excelData.comments = all_comments
        })
        builder.addCase(getCommentDetails.fulfilled, (state, action) => {
            const { cell_no, comments } = action.payload.data
            state.currentComment = comments
            state.currentCommentCell = cell_no
        })
        builder.addCase(addCommentDetails.fulfilled, (state, action) => {
            state.successMsg = 'Successfully Added comment'
        })
        builder.addCase(addCommentDetails.rejected, (state, action) => {
            state.errorMsg = 'Failed to add comment'
        })
        builder.addCase(uploadFiles.pending, (state) => {
            state.successMsg = 'Uploading...';
            state.uploadFilesSuccess = false;
            state.billsUploading = true;
        })
        builder.addCase(uploadFiles.fulfilled, (state, action) => {
            state.successMsg = 'Successfully uploaded file(s)'
            state.uploadFilesSuccess = true
            state.uploadFilesData = action.payload
            state.billsUploading = false;
        })
        builder.addCase(uploadFiles.rejected, (state, action) => {
            state.errorMsg = 'Failed to upload file(s)'
            state.uploadFilesSuccess = false
            state.billsUploading = false;
        })
        builder.addCase(handleSheetApproval.fulfilled, (state, action) => {
            state.handleSheetApprovalState.success = true
            state.handleSheetApprovalState.data = action.payload
            state.handleSheetApprovalState.error = null
        })
        builder.addCase(handleSheetApproval.rejected, (state, action) => {
            state.handleSheetApprovalState.success = false
            state.handleSheetApprovalState.data = action.payload;
            state.handleSheetApprovalState.error = true
        })
        builder.addCase(createRefundPeriod.fulfilled, (state, action) => {
            state.createRefundPeriodState.success = true
            state.createRefundPeriodState.data = action.payload
            state.createRefundPeriodState.error = null
        })
        builder.addCase(createRefundPeriod.rejected, (state, action) => {
            state.createRefundPeriodState.success = false
            state.createRefundPeriodState.data = action.payload;
            state.createRefundPeriodState.error = true
        })
        builder.addCase(handleSheetApprovalManager.fulfilled, (state, action) => {
            state.handleSheetApprovalState.success = true
            state.handleSheetApprovalState.data = action.payload
            state.handleSheetApprovalState.error = null
        })
        builder.addCase(handleSheetApprovalManager.rejected, (state, action) => {
            state.handleSheetApprovalState.success = false
            state.handleSheetApprovalState.data = action.payload;
            state.handleSheetApprovalState.error = true
        })
    },
})




export const { clearMessages } = refundSlice.actions


export default refundSlice.reducer