import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, InputGroup , FormCheck, Row, Table } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { CURRENT_PAGE, PER_PAGE} from '../../constants/staff';
import { useDispatch, useSelector } from 'react-redux';
import { getStaffList, updateStatus, clearMessages } from '../../slices/staff';
import { toast } from 'react-toastify';

const StaffList = () => {
    const dispatch = useDispatch();
    const [current_page, setCurrentPage] = useState(CURRENT_PAGE);
    const searchRef = useRef();
    const { 
        staffData,
        successMsg,
        errorMsg,
        total_count,
    } = useSelector((state) => state.staff);

    const switchToggle = (id, e) => {
        dispatch(updateStatus({id, active:  e.target.checked, page: current_page}));
        handleSearch();
    }

    const handleSearch = () => {
        const searchText = searchRef.current.value.trim();
        if (searchText) {
          dispatch(getStaffList({page: current_page, search: searchText}))
        } else {
          dispatch(getStaffList({page: current_page}))
        }
      }

    useEffect(() => {
        if (!!errorMsg) {
            toast.error(errorMsg, {
                position: 'top-center'
            });
        }
        if (!!successMsg) {
            toast.success(successMsg, {
                position: 'top-center'
            })
        }
        dispatch(clearMessages());
    }, [successMsg, errorMsg])

    useEffect(() => {
        dispatch(getStaffList({page: current_page}))
    },[])

  return (
    <Row>
        <Col xl={12} lg={12}>
            <Card>
                <Card.Header className="d-flex justify-content-between">
                    <Col md={3}>
                        <div className="header-title">
                            <h4 className="card-title">Staff List</h4>
                        </div>
                    </Col>
                    <Col md={3} className="text-right">
                        <NavLink to="add-staff"><Button variant="primary" >
                            <span>+</span> Add New
                        </Button>   
                        </NavLink>
                    </Col>
                    <Col
                    sm={6}
                    md={6}
                    className="text-right"
                    >
                    <div id="user_list_datatable_info" class="dataTables_filter">
                        <Form.Group
                            className="form-group mb-0"
                        >
                            <InputGroup>
                            <Form.Control
                                placeholder="Search"
                                ref={searchRef}
                            />
                            <Button variant="primary" onClick={handleSearch} ><i className="ri-search-line"></i></Button>
                            </InputGroup>
                        </Form.Group>
                    </div>
                </Col>
                </Card.Header>
                <Card.Body>
                    <Col>
                        {!!staffData.length && <Table responsive bordered striped id="staff-list-table" className="mt-4">
                        <thead>
                            <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Contact</th>
                            <th scope="col">Email</th>
                            <th scope="col">Role</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {staffData.map(staff => {
                            console.log(staff)
                                return (<tr key={staff.id}>
                                <td >{staff.name}</td>
                                <td>{staff.phone}</td>
                                <td>{staff.email}</td>
                                <td>{staff.role.name}</td>
                                    <td className="align-middle"><FormCheck
                                        inline
                                        id="staffStatus"
                                        type="switch"
                                        defaultChecked={staff.active}
                                        onChange={(e) => switchToggle(staff.id,e)}
                                    /></td>
                                    <td>
                                    <Row>
                                    <Col md={2}>
                                    {<NavLink to={`edit-staff/${staff.id}`}><Button
                                        variant="link"
                                        className="dropdown-item"
                                        title="Edit Staff"
                                    >
                                        <i className="ri-pencil-fill mr-2"></i>
                                    </Button></NavLink>
                                    }
                                    </Col> 
                                    </Row>            
                                    </td>
                            </tr>)
                            })}

                        </tbody>
                        </Table>
                        }
                        {
                        !staffData.length && 'No Staffs Found'
                        
                        }
                    </Col>
                </Card.Body>
            </Card>
        </Col>
    </Row>
  )
}

export default StaffList
