import { useState } from 'react';
import { Row, Col } from 'react-bootstrap'
import Sidebar from "./Sidebar";
import { ToastContainer } from 'react-toastify';

import Header from "./Header";
const Base = (props) => {
    const [baseSize, setBaseSize] = useState(2)
    const { children, header, headerTitle } = props;

    const changeSidebarWidth = (close) => {
        if (close) {
            setBaseSize(0)
        } else {
            setBaseSize(2)
        }
    }

    return (<>
        <div className="wrapper">
            <div className="iq-sidebar  sidebar-default">
                <Sidebar closeSidebar={changeSidebarWidth} />
            </div>
            <Header showLogo={!!baseSize} type={header} headerTitle={headerTitle} />
            <ToastContainer />
            <div className='content-page'>
                    {children}
            </div>
        </div>
    </>
    )
}
export default Base;