import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../services/instance';
import { LIST_NOTIFICATIONS } from '../constants/services';
const CURRENT_PAGE = 1;
const PER_PAGE = 50;

const generateNotificationUrl = (notification) => {
    let baseUrl = `/`; // Adjust as per your app's base route

    switch (notification.module) {
        case "GSTR1":
            baseUrl += `gst-forms/gstr1`;
            if (notification?.params?.month && notification?.params?.year) {
                baseUrl += `?month=${notification.params.month}&year=${notification.params.year}`;
            }
            break;

        case "GSTR3B":
            baseUrl += `gst-forms/gstr3b`;
            if (notification?.params?.month && notification?.params?.year) {
                baseUrl += `?month=${notification.params.month}&year=${notification.params.year}`;
            }
            break;
        
        case "REFUND":
            baseUrl += `gst-forms/refunds`;
            break;

        case "GENERAL":
            baseUrl += `gst-forms`;
            break;
        default:
            baseUrl += ''; // Fallback for other routes
    }

    return baseUrl;
};

const updateChatParams = (data, chat_type, action) => {
    if (action === 'add' && !data.includes(chat_type)) {
        // Add chat_type if it doesn't already exist
        data.push(chat_type);
    } else if (action === 'remove') {
        // Remove chat_type if it exists
        data = data.filter(type => type !== chat_type);
    }
};

export const getNotifications = createAsyncThunk('notification/getNotifications', async ({page}, thunkAPI) => {
    try {
        const data = {
            page: CURRENT_PAGE,
            perpage: PER_PAGE
        }
        if (page ) data.page  = page;
        //if (latest_notification_id ) data.latest_id  = latest_notification_id;
        const response = await axiosInstance.post(LIST_NOTIFICATIONS, data);
        return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response ? error.response?.data?.message : 'Notification Fetching failed')
    }
})

export const fetchNewNotifications = createAsyncThunk('notification/fetchNewNotifications', async ({page, latest_notification_id}, thunkAPI) => {
    try {
        const data = {
            page: CURRENT_PAGE,
            perpage: PER_PAGE
        }
        if (page ) data.page  = page;
        if (latest_notification_id ) data.latest_id  = latest_notification_id;
        const response = await axiosInstance.post(LIST_NOTIFICATIONS, data);
        return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response ? error?.response?.data?.message : 'Notification Fetching failed')
    }
})

export const notificationSlice = createSlice({
    name: 'notification',
    initialState: {
        isNotificationRoute: false,
        notification: null,
        notificationUrl: null,
        notificationList: [],
        loading: false,
        latestNotificationId: null,
        newNotificationFound: false,
        fcmNotification: {},
        fcmNotificationReceived: false,
        fcmChatNotificationReceived: false,
        fcmDeviceToken: null,
        fcmChatParams: [],
    },
    reducers: {
        setNotification: (state, action) => {
            state.notification = action.payload;
            state.isNotificationRoute = true;
            state.notificationUrl = generateNotificationUrl(action.payload);
        },
        clearNotification: (state) => {
            state.isNotificationRoute = false;
            state.notification = null;
            state.notificationUrl = null;
            state.newNotificationFound = false;
        },
        setFcmNotification: (state, action) => {
            state.fcmNotification = action.payload;
            state.newNotificationFound = true;
            state.fcmNotificationReceived = true;
            if(action.payload.is_chat === "1"){
                state.fcmChatNotificationReceived = true;
                updateChatParams(state.fcmChatParams, action.payload.chat_type, 'add');
            }
        },
        clearFcmNotification: (state) => {
            state.fcmNotification = {};
            state.fcmChatNotificationReceived = false;
            state.fcmNotificationReceived = false;
        },
        setFcmDeviceToken: (state, action) => {
            state.fcmDeviceToken = action.payload;
            localStorage.setItem("preface_client_fcm_token", action.payload)
        },
        clearFcmChat: (state, action) => {
            updateChatParams(state.fcmChatParams, action.payload?.chat_type, 'remove');
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getNotifications.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getNotifications.fulfilled, (state, action) => {
            state.loading = false;
            state.notificationList = action.payload.data?.lists;
            if (action.payload.data?.lists.length > 0) {
                state.latestNotificationId = action.payload.data?.lists[0].id;  // store the id of the 0th index
            }
        })
        builder.addCase(getNotifications.rejected, (state, action) => {
            state.loading = false;
            console.log(action);
            state.errorMsg = action.payload?.data ? action.payload?.data : 'Failed to fetch notifications';
        })

        builder.addCase(fetchNewNotifications.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(fetchNewNotifications.fulfilled, (state, action) => {
            state.loading = false;
            //state.notificationList = action.payload.data?.lists;
            if (action.payload.data?.lists.length > 0) {
               state.newNotificationFound = true;
            }
        })
        builder.addCase(fetchNewNotifications.rejected, (state, action) => {
            state.loading = false;
            console.log(action);
            state.errorMsg = action.payload?.data ? action.payload?.data : 'Failed to fetch notifications';
        })
    }
})

export const { setNotification,clearNotification, setFcmNotification, clearFcmNotification, setFcmDeviceToken, clearFcmChat } = notificationSlice.actions;

export default notificationSlice.reducer;