import { FilePond } from 'react-filepond'
import { Button } from 'react-bootstrap'
import 'filepond/dist/filepond.min.css'
import { useState } from 'react'
import toast from "react-hot-toast";

const GstrUploadBills = (props) => {
    const { handleUploadFile } = props;
    const [files, setFiles] = useState([]);
    const [textarea, setTextArea] = useState('');
    const [errors, setErrors] = useState({
        textarea: "",
    });
    const uploadLimit = 50;

    const handleChange = (e) => {
        setErrors({textarea: ''});
        if(e.target.value === ''){
            setErrors({textarea: 'Notes is Required'});
        }
        setTextArea(e.target.value)
    }

    const handleSubmit = () => {
        if (typeof handleUploadFile == 'function') {
            if(!textarea){
                setErrors({textarea: 'Notes is Required'});
                return;
            }
            const fileData = files.map(fileItem => fileItem.file)
            handleUploadFile(fileData, textarea)
            setFiles([]);
            setTextArea('');
        }
    }
    const handleWarning = (error, file) => {
    const total_length = files.length + file.length;
    if (total_length > uploadLimit) {
            toast(`You can't upload more than ${uploadLimit} files.`);
            return;
        }
    };
      

    return (<><FilePond
        className="my-pond filepond filepond_ip filepond-input-multiple"
        files={files}
        onupdatefiles={setFiles}
        allowMultiple={true}
        maxFiles={uploadLimit}
        onwarning={handleWarning}
        name="files" /* sets the file input name, it's filepond by default */
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
    /><div class="pa-note-block">
            <h5 className="mb-3">Notes</h5>
            <div className='input-group has-validation'>
                <textarea id="SRtextarea" className={`form-control ${errors.textarea ? 'is-invalid' : ''}`} onChange={handleChange} rows="3" value={textarea}></textarea>
                {errors.textarea && <div class="invalid-feedback">{errors.textarea}</div>}
            </div>
            <Button className="mt-2" onClick={handleSubmit}>Upload</Button>
           
        </div></>)
}

export default GstrUploadBills;