import Base from '../../common/Base';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChatPopup from '../../common/ChatPopup';
import {
  Nav,
  Row,
  Col,
  Container,
  Card,
  CardHeader,
  Button,
  Form,
  CardBody,
  Table,
  FormCheck,
  Alert,
  CardFooter,
  Breadcrumb,
  InputGroup,
  Tabs,
  Tab
} from 'react-bootstrap';
import { getLitigations } from '../../../slices/forms/litigation';


const LitigationList = () => {

  const { litigationData, loading, error } = useSelector((state) => state.litigation);
  
  const dispatch = useDispatch();
console 
  useEffect(() => {
    dispatch(getLitigations({}));
  }, [dispatch]);

  return (
    <Base header="litigations" headerTitle={'Litigations'}>
      <Card>
        <Card.Body>
          <Row>
            <Col sm={12}>
              <Card>
                <Tabs
                  defaultActiveKey="litigationList"
                  id="litigation-management-tabs"
                  className="mb-3">
                  <Tab eventKey="litigationList" title="Litigation List">
                    <CardBody>
                      <Col>
                        <Table
                          responsive
                          bordered
                          striped
                          id="litigation-list-table"
                          className="mt-4">
                          <thead>
                            <tr>
                              <th scope="col">SL.No:</th>
                              <th scope="col">Particulars</th>
                              <th scope="col">Period</th>
                              <th scope="col">Current Status</th>
                              <th scope="col">Final Status</th>
                              <th scope="col">Created Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {litigationData.length ? (
                              litigationData.map((litigation, index) => (
                                <tr key={litigation.id}>
                                  <td>{index + 1}</td>
                                  <td>{litigation.particular}</td>
                                  <td>{litigation.period}</td>
                                  <td>{litigation.current_status}</td>
                                  <td>{litigation.final_status}</td>
                                  <td>{litigation.created_date.split('T')[0]}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="6" className="text-center">
                                  No Litigation Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </Col>
                    </CardBody>
                  </Tab>
                </Tabs>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <ChatPopup chat_type="LITIGATION" />
    </Base>
  );
};
export default LitigationList;
