import { Row, Col, Form, InputGroup, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { format } from 'date-fns'
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedMonthYear } from "../../../slices/common";
import { useYearMonth } from "../../YearMonth";
import { CURRENT_MONTH_ENABLE_DAY } from "../../../constants/common";




const GstrHeader = ({headerTitle, type}) => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const currentDate = new Date();
    const maxDate = currentDate.getDate() < CURRENT_MONTH_ENABLE_DAY
    ? new Date(currentDate.getFullYear(), currentDate.getMonth(), 0) // Last day of previous month
    : currentDate; // Current date
    const [selectedMonth, setMonth] = useState(maxDate)
    const [selectedYear, setYear] = useState(new Date)
    const {setSelectedMonth, setSelectedYear} = useYearMonth()
    const formatMonth = (date) => {
        return date ? format(date, 'MMMM') : '';
    };
    const formatYear = (date) => {
        return date ? format(date, 'yyyy') : '';
    };

    
    const getExcludedDates = () => {
        const excludedMonths = []; // January (1), March (3) as examples
        const excludedDates = [];
        for (let year = 1900; year <= 2100; year++) { // Adjust range as needed
          excludedMonths.forEach(month => {
            excludedDates.push(new Date(year, month - 1, 1)); // Month is 0-indexed
          });
        }
        return excludedDates;
    };
    
    const excludedDates = getExcludedDates();
    useEffect(() => {
        if(type === 'gstr' || type === 'gstr3b'){
            console.log('Loading')
            const params = new URLSearchParams(location.search);
            const monthParam = params.get("month");
            const yearParam = params.get("year");
            const currentMonth = new Date().getMonth() + 1;
            const currentYear = new Date().getFullYear();

            let inputMonth = parseInt(monthParam) || currentMonth; // Default to current month if not present
            const inputYear = parseInt(yearParam) || currentYear; // Default to current year if not present

            if (inputYear < currentYear || (inputYear === currentYear && inputMonth <= currentMonth)) {
                if (inputMonth === currentMonth) {
                if (new Date().getDate() < CURRENT_MONTH_ENABLE_DAY) {
                    inputMonth = currentMonth - 1;
                    if (inputMonth === 0) {
                    inputMonth = 12;
                    inputYear -= 1; // Decrease year
                    }
                }
                }
            } else {
                inputMonth = currentMonth;
            }
            
            const monthDate = new Date(inputYear, inputMonth - 1); // Month is 0-indexed
            setMonth(monthDate);
            setYear(new Date(inputYear, inputMonth - 1)); // Use monthDate to set the year

            // Set values to datepicker
            console.log('Set values to datepicker');
            dispatch(setSelectedMonthYear({ month: inputMonth, year: inputYear }));

            // Update the URL if changes were made
            params.set("month", inputMonth);
            params.set("year", inputYear);
            navigate({
                pathname: location.pathname,
                search: `?${params.toString()}`
            }, { replace: true });
        }
    }, [location.search, type, dispatch, navigate]);
    

    const handleSubmit = () => {
        const params = new URLSearchParams(location.search);
        const monthMap = {
            January: 1,
            February: 2,
            March: 3,
            April: 4,
            May: 5,
            June: 6,
            July: 7,
            August: 8,
            September: 9,
            October: 10,
            November: 11,
            December: 12,
        };

        const monthNumber = monthMap[formatMonth(selectedMonth)]; // Convert month name to number
        const yearNumber = parseInt(formatYear(selectedYear), 10);

        setSelectedMonth(monthNumber);
        params.set("month", monthNumber);
        params.set("year", yearNumber);
        navigate({
            pathname: location.pathname,
            search: `?${params.toString()}`
        }, { replace: true });
        dispatch(setSelectedMonthYear({ month: monthNumber, year: yearNumber }));

    }
    
    return (<Row>
        <Col lg={2}>
            <h3>{headerTitle ? headerTitle : 'GSTR-1'}</h3>
        </Col>
        {(type === 'gstr' || type === 'gstr3b') && 
        <Col lg={10}>
            <div className="pa-choose-date">
                <Row>
                    <Col lg={2} >
                        <Form.Label>
                            Choose Year:
                        </Form.Label>

                    </Col>
                    <Col lg={3} className="date datepicker datepicker1">

                        <DatePicker
                            selected={selectedYear}
                            onChange={(date) => setYear(date)}
                            dateFormat="MMMM"  // Format to show only month and year
                            showYearPicker  // Show only month and year picker
                            // Use Bootstrap styling
                            customInput={
                                <InputGroup>
                                    <InputGroup.Text ><i className="las la-calendar"></i></InputGroup.Text>
                                    <Form.Control
                                        value={formatYear(selectedYear)}
                                    />
                                </InputGroup>
                            }
                        />

                    </Col>
                    <Col lg={2} >
                        <Form.Label>
                            Choose Month:
                        </Form.Label>

                    </Col>
                    <Col lg={4} className="date datepicker datepicker1">

                        <DatePicker
                            selected={selectedMonth}
                            onChange={(date) => setMonth(date)}
                            dateFormat="yyyy"  // Format to show only month and year
                              // Show only month and year picker
                            showMonthYearPicker
                            excludeDates={excludedDates}
                            maxDate={maxDate}
                            customInput={
                                <InputGroup>
                                    <InputGroup.Text ><i className="las la-calendar"></i></InputGroup.Text>
                                    <Form.Control
                                        value={formatMonth(selectedMonth)}
                                    />
                                </InputGroup>
                            }
                        />
                    </Col>
                    <Col lg={1}>
                        <Button onClick={handleSubmit}>Submit</Button>
                    </Col>
                </Row>
            </div>
        </Col>
        }
    </Row>)
}

export default GstrHeader;