import Base from '../../common/Base';
import { useEffect, useState } from 'react';
import { Card, Tab, Nav, Row, Col } from 'react-bootstrap';
import Gstr3bSidebar from '../sidebar/Gstr3bSidebar';
import GstrUploadBills from './../tables/GstrUploadBills';
import uploading_image from '../../../assets/images/up-loading.png';
import {
  uploadFiles,
  clearMessages,
  handleSheetApproval,
  handleSheetApprovalManager
} from '../../../slices/forms/gstr3b';
import { clearNotification } from '../../../slices/notification';
import { useDispatch, useSelector } from 'react-redux';
import BillsSidebar from '../sidebar/BillsSidebar';
import axiosInstance from '../../../services/instance';
import ChatPopup from '../../common/ChatPopup';
import ViewSheetSummary from '../ViewSheetSummary';
import toast from 'react-hot-toast';
import Loader from '../../common/Loader';
import useCheckAuthorization from '../../../hooks/checkAuthorization';
import { CRUD_PERMISSIONS } from '../../../constants/staff';

const Gstr3B = () => {
  const [key, setKey] = useState('view_form');
  const dispatch = useDispatch();
  const changeTab = (k) => {
    setKey(k);
  };
  const [uploadedBills, setUplaodedBills] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sheetData, setSheetData] = useState(null);
  const [sheetSummaryData, setSheetSummaryData] = useState(null);
  const { uploadFilesSuccess, billsUploading, uploadFilesData, handleSheetApprovalState } =
    useSelector((state) => state.gstr3b);
  const { isNotificationRoute, notification } = useSelector((state) => state.notification);
  const { selectedMonth, selectedYear } = useSelector((state) => state.common);
  const isManager = useCheckAuthorization(CRUD_PERMISSIONS.sheet.client_manager_role);

  const uploadFileData = (files, section_type, notes) => {
    dispatch(uploadFiles({ month: selectedMonth, year: selectedYear, files, section_type, notes }));
  };

  const handleSheetSubmit = (sheetId, status) => {
    setLoading(true);
    dispatch(
      handleSheetApproval({
        month: selectedMonth,
        year: selectedYear,
        approve: status,
        isManager,
        sheetId
      })
    );
  };
  const handleSheetSubmitManager = (sheetId, status) => {
    setLoading(true);
    dispatch(
      handleSheetApprovalManager({
        month: selectedMonth,
        year: selectedYear,
        approve: status,
        isManager,
        sheetId
      })
    );
  };
  const normalizeData = async (data) => {
    return data.map((item) => ({
      ...item,
      note_files: item.gstr3b_note_files
    }));
  };

  const getUploadedBills = async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.post('/api/v1/gstr3b/lists', {
        year: selectedYear,
        month: selectedMonth,
        perPage: 10,
        page: 1
      });
      const formattedData = await normalizeData(res.data.data.list);
      setUplaodedBills(formattedData);
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  };

  const getSheet = async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.post('/api/v1/gstr3b/view/sheet', {
        year: selectedYear,
        month: selectedMonth
      });
      if (res.data?.data) setSheetSummaryData(res.data?.data);
      if (res.data?.data?.sheet !== null) {
        setSheetData(res.data?.data?.sheet);
      } else {
        setSheetData(null);
      }
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  };
  const clearFormUpload = async () => {
    dispatch(clearMessages(true));
    await getUploadedBills();
  };

  if (uploadFilesSuccess) {
    clearFormUpload();
  }

  if (handleSheetApprovalState.success) {
    dispatch(clearMessages(true));
    getSheet();
  }
  if (handleSheetApprovalState.error) {
    dispatch(clearMessages(true));
    getSheet();
  }

  const handleDownloadZip = async (item) => {
    try {
      const promise = axiosInstance.post(
        `/api/v1/gstr3b/download/zip`,
        {
          note_id: item.id
        },
        {
          responseType: 'blob' // Important! Tells axios to handle the response as a blob
        }
      );
      const response = await toast.promise(promise, {
        loading: 'Downloading...',
        success: <b>Downloaded!</b>,
        error: <b>Downloading Failed.</b>
      });
      // Create a URL for the blob
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a link element
      const link = document.createElement('a');
      link.href = url;
      const fileName = item.notes.replace(/ /g, '_');
      link.setAttribute('download', `${fileName}.zip`); // Specify the filename

      // Append to the document and click the link to trigger download
      document.body.appendChild(link);
      link.click();

      // Cleanup
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('error', error);
    } finally {
    }
  };

  const handleDeleteBill = async (billId) => {
    try {
      setLoading(true);
      const promise = axiosInstance.post('/api/v1/gstr3b/delete/file', {
        file_id: billId
      });
      const response = await toast.promise(promise, {
        loading: 'Deleting...',
        success: <b>Deleted!</b>,
        error: <b>Deleting Failed.</b>
      });
      getUploadedBills();
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  };

  //const { comments, summary } = useSelector((state) => state.gstr.excelData);
  useEffect(() => {
    if (key === 'upload_bills') {
      getUploadedBills();
    }
    if (key === 'view_form') getSheet();
  }, [key, selectedMonth, selectedYear]);
  
  useEffect(() => {
    if(isNotificationRoute){
      /*if(notification?.parameters?.selected_tab === 1){
        setKey('view_form');
      }else if(notification?.parameters?.selected_tab === 2){
        setKey('upload_bills');
      }*/
      dispatch(clearNotification());
      
    }
  }, [isNotificationRoute])

  return (
    <Base header="gstr3b" headerTitle={'GSTR - 3B'}>
      <Card>
        <Card.Body>
          <Tab.Container id="left-tabs-example" activeKey={key} onSelect={changeTab}>
            <Nav variant="tabs" as="ul" className="pa-nav-tabs-1 gstr-tab">
              <Nav.Item as="li">
                <Nav.Link eventKey="view_form">View Form</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link eventKey="upload_bills">Upload Bills</Nav.Link>
              </Nav.Item>
            </Nav>
            {loading ? (
              <div class="text-center">Loading...</div>
            ) : (
              <Tab.Content>
                <Tab.Pane eventKey="view_form">
                  <Row style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                    <Col xl={9}>
                      {!sheetData ? (
                        <h4 className="text-center mt-4">No Summary Uploaded For the period !</h4>
                      ) : (
                        <ViewSheetSummary
                          sheetSummaryData={sheetSummaryData}
                          handleSheetSubmit={handleSheetSubmit}
                          handleSheetSubmitManager={handleSheetSubmitManager}
                          sheetType="gstr3b"
                          getSheet={getSheet}
                        />
                      )}
                    </Col>
                    <Col xl={3}>
                      <Gstr3bSidebar activeTab={key} />
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="upload_bills">
                  <Row>
                    <Col xl={8}>
                      <Tab.Container defaultActiveKey="shipping_bills">
                        <Nav variant="tabs" as="ul" className="pa-nav-tabs-2 pa-nav-icon-tabs">
                          <Nav.Item as="li">
                            <Nav.Link eventKey="shipping_bills">
                              Shipping Bills
                              <span className="pa-check-mark">
                                <i className="fas fa-check-circle"></i>
                              </span>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link eventKey="sales_register">
                              Sales register
                              <span className="pa-check-mark">
                                <i className="fas fa-check-circle"></i>
                              </span>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link eventKey="invoices">
                              Invoices
                              <span className="pa-check-mark">
                                <i className="fas fa-check-circle"></i>
                              </span>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link eventKey="others">
                              Others
                              <span className="pa-check-mark">
                                <i className="fas fa-check-circle"></i>
                              </span>
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                        <Tab.Content>
                          {[
                            { key: 'shipping_bills', label: 'Upload Shipping Bill', section: 1 },
                            { key: 'sales_register', label: 'Sales Register', section: 2 },
                            { key: 'invoices', label: 'Invoices', section: 3 },
                            { key: 'others', label: 'Others', section: 4 }
                          ].map((tab) => (
                            <Tab.Pane eventKey={tab.key}>
                              <h4
                                className="jl-heading mt-0 mb-10 inline card-title bold-font"
                                id="Filechange">
                                <span className="pa-upload-icon">
                                  <img src={uploading_image} alt="" />
                                </span>
                                {tab.label}
                              </h4>
                              {billsUploading ? (
                                <Loader content="Uploading File! Please wait. Do not refresh or press back button" />
                              ) : (
                                <GstrUploadBills
                                  handleUploadFile={(fileData, notes) =>
                                    uploadFileData(fileData, tab.section, notes)
                                  }
                                />
                              )}
                            </Tab.Pane>
                          ))}
                        </Tab.Content>
                      </Tab.Container>
                    </Col>
                    <Col xl={4}>
                      <BillsSidebar
                        uploadedBills={uploadedBills}
                        handleDownloadZip={handleDownloadZip}
                        handleDeleteBill={handleDeleteBill}
                      />
                    </Col>
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            )}
          </Tab.Container>
        </Card.Body>
      </Card>
      <ChatPopup chat_type="GSTR3B" />
    </Base>
  );
};
export default Gstr3B;
