// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

/* testing */
/*
const FIREBASE_CONFIG = {
  apiKey: "AIzaSyAyi1V6YnkXdFspZ7RmXypfrvEas-YdAiU",
  authDomain: "chat-app-d553c.firebaseapp.com",
  projectId: "chat-app-d553c",
  storageBucket: "chat-app-d553c.appspot.com",
  messagingSenderId: "966075719785",
  appId: "1:966075719785:web:4553e1276edb9822b7c2f8",
  measurementId: "G-Y87C51SV6Q"
};

const VAPID_KEY = 'BDYtX4QsA1DZscJ7uiMVWhddH69yr6U5K1lkFonFjnW8x5ATqEmwr1waq2MFJ2ohGtrcQfsSbmqwtEacAhZrefM';
*/
/* testing */


/* production */

const FIREBASE_CONFIG = {
  apiKey: "AIzaSyCopteGYdsbVuf2eTpvar26TF1SzpIkPug",
  authDomain: "preface-24225.firebaseapp.com",
  projectId: "preface-24225",
  storageBucket: "preface-24225.appspot.com",
  messagingSenderId: "958249678337",
  appId: "1:958249678337:web:451f5355932ae951483200",
  measurementId: "G-FQKFYEV7ZD"
};

const VAPID_KEY = 'BMeui1dwlceYzAn7kCmVm0VCHvvr66nf9F8AtL-Yr4-CVe1lb8ieJJGmgcZppRcpb0_jfMm4Oe9y6ZrkqlshP0U';

/* production */

// Initialize Firebase
const app = initializeApp(FIREBASE_CONFIG);
export const messaging = getMessaging(app);

export const generateToken = async () => {
  try{
    const permission = await Notification.requestPermission();
    //granted denied
    if(permission === 'granted'){
      const token = await getToken(messaging, {
        vapidKey: VAPID_KEY
      });
     return token;
    }else {
      // Notify the user to enable notifications again
      alert('Please enable notifications in your browser settings to receive alerts.');
      return null; // Return null if permission is denied
    } 
  }catch (error) {
    console.error('Generate push token error', error);
    //throw error;
  }
}