import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { UPDATE_PROFILE, UPDATE_PASSWORD, PROFILE } from '../constants/services'
import axiosInstance from '../services/instance'


export const getProfile = createAsyncThunk(
    'user/getProfile',
    async (thunkAPI) => {
        try{
            const response = await axiosInstance.get(PROFILE)
            return response.data;
        } catch (error) {
            // Log the error for debugging

            // Return the error message or a custom error
            return thunkAPI.rejectWithValue(error.response ? error.response.data : 'Profile Fetching failed');
        }
    },
)


export const updateProfile = createAsyncThunk(
    'user/updateProfile',
    async ({ name, mobile }, thunkAPI) => {
        try{
            const response = await axiosInstance.post(UPDATE_PROFILE, { name, phone:mobile })
            return response.data
        } catch (error) {
            // Log the error for debugging

            // Return the error message or a custom error
            return thunkAPI.rejectWithValue(error.response ? error.response.data : 'Failed to update profile');
        }
    },
)

export const updatePassword = createAsyncThunk(
    'user/updatePassword',
    async ({  oldPassword, newPassword, repeatPassword }, thunkAPI) => {
        try{
            const response = await axiosInstance.post(UPDATE_PASSWORD, { old_password: oldPassword, new_password:newPassword, confirm_new_password:repeatPassword })
            return response.data
        } catch (error) {
            // Log the error for debugging

            // Return the error message or a custom error
            return thunkAPI.rejectWithValue(error.response ? error.response.data : 'Failed to update password');
        }
    },
)

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        isAuthenticated: false,
        userData: {
            email: '',
            id: '',
            name: '',
            permission_code: [],
            role: ''
        },
        successMsg: '',
        errorMsg: '',
        password:{
            successMsg: '',
            errorMsg: '',
        },
        profile: {
            success: false
        }
    },
    reducers: {
        setAuthenticatedStatus: (state, action) => {
            state.isAuthenticated = action.payload
        },
        setUserData: (state, action) => {
            state.userData = action.payload
        },
        clearUserData: (state, action) => {
            state.isAuthenticated = false,
                state.userData = {
                    email: '',
                    id: '',
                    name: '',
                    permission_code: [],
                    role: '',
                    mobile: '',
                }
        },
        clearMessages: (state) => {
            state.successMsg = ''
            state.errorMsg = ''
            state.password.successMsg = ''
            state.password.errorMsg = ''
            state.profile.success = false;
        }

    },
    extraReducers: (builder) => {
        builder.addCase(updateProfile.fulfilled, (state, action) => {
            state.successMsg = 'Successfully updated profile'
        })
        builder.addCase(updateProfile.rejected, (state, action) => {
            state.errorMsg = 'Failed to update profile'
        })

        builder.addCase(updatePassword.fulfilled, (state, action) => {
            state.password.successMsg = 'Successfully updated password'
        })
        builder.addCase(updatePassword.rejected, (state, action) => {
            state.password.errorMsg = action?.payload?.message || 'Failed to update password';
        })
        builder.addCase(getProfile.fulfilled, (state, action) => {
            const { email, id, name, phone, permission_code, role } = action.payload?.data;
            state.profile.success = true;
            state.userData = { email, id, name, mobile:phone, permission_code, role: role?.name };
        })
        builder.addCase(getProfile.rejected, (state, action) => {
            state.profile.success = false;
        })

    }

})

export const { setAuthenticatedStatus, setUserData, clearUserData, clearMessages } = userSlice.actions

export default userSlice.reducer