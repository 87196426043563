import React from 'react'
import { Badge, Button, Col, Row } from 'react-bootstrap';

const ApproveRejectOptions = ({isManager, sheetSummaryData, loading, handleSheetSubmit, handleSheetClose, handleSheetSubmitManager}) => {
  return (
    <>
        {/* Approval Status */}   
        {(sheetSummaryData.sheet.preface_approval === 0 || (sheetSummaryData.sheet.preface_approval === 1 && sheetSummaryData.sheet.status === 0))  && <h5 className=""><Badge bg="primary">In Progress</Badge></h5>}
        {sheetSummaryData.sheet.status === 1 && sheetSummaryData.sheet.client_approval === 1 && 
            <h5 className=""><Badge bg="primary">Approved {!isManager && `by Client`}</Badge></h5>
        }
        {sheetSummaryData.sheet.status === 2 && sheetSummaryData.sheet.client_approval === 1 && 
            <h5 className=""><Badge bg="secondary">Rejected {!isManager && `by Client`}</Badge></h5>
        }
        {/* Staff */}
        {!isManager && (sheetSummaryData.sheet.status === 1 || sheetSummaryData.sheet.status === 2) && sheetSummaryData.sheet.client_approval === 0 && sheetSummaryData.sheet.preface_approval === 1 &&  sheetSummaryData.sheet.client_approval === 0 && 
            <h5 className=""><Badge bg="primary">Waiting For Client Approval</Badge></h5>
        }
        {sheetSummaryData.sheet.status === 3 && sheetSummaryData.sheet.preface_approval === 1 && sheetSummaryData.sheet.client_approval === 1 &&  (
            <Row>
            <Col>
            <Button type="submit" variant="primary" size="sm" onClick={() => {handleSheetSubmit(sheetSummaryData.sheet.id, 1); handleSheetClose()}} disabled ={loading} >
            Approve
            </Button>
            <Button type="submit" variant="outline-primary" size="sm" onClick={() => {handleSheetSubmit(sheetSummaryData.sheet.id, 2); handleSheetClose()}} disabled ={loading} className="ml-2">
                Reject
            </Button>
            </Col>
            </Row>
        )}
        {/* Manager */}
        {isManager && 
            <>
            {sheetSummaryData.sheet.preface_approval === 1 && sheetSummaryData.sheet.status === 1 && sheetSummaryData.sheet.client_approval === 0 && 
            <>
                <h5 className=""><Badge bg="primary">Approved by Staff</Badge></h5>
            </>
            }
            {sheetSummaryData.sheet.preface_approval === 1 && sheetSummaryData.sheet.status === 2 && sheetSummaryData.sheet.client_approval === 0 && 
            <>
                <h5 className=""><Badge bg="primary">Rejected by Staff</Badge></h5>
            </>
            }
            {sheetSummaryData.sheet.preface_approval === 1 && (sheetSummaryData.sheet.status === 1 || sheetSummaryData.sheet.status === 2) && sheetSummaryData.sheet.client_approval === 0 && 
            
                <Row>
                <Col>
                <Button type="submit" variant="primary" size="sm" onClick={() => {handleSheetSubmitManager(sheetSummaryData.sheet.id, 1); handleSheetClose()}} disabled ={loading} >
                Confirm
                </Button>
                <Button type="submit" variant="outline-primary" size="sm" onClick={() => {handleSheetSubmitManager(sheetSummaryData.sheet.id, 2); handleSheetClose()}} disabled ={loading} className="ml-2">
                    Deny
                </Button>
                </Col>
                </Row>
            
            }
            </>
        }
    </>
  )
}

export default ApproveRejectOptions
