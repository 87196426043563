import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {GoCommentDiscussion} from 'react-icons/go'
import axiosInstance from '../../../services/instance';
import { FaCircleDot, FaCircleUser } from "react-icons/fa6";
import moment from 'moment';

const Gstr1Sidebar = ({activeTab}) => {
  const [key, setKey] = useState('downloads');
  const changeTab = (k) => {
    setKey(k);
  };
  const {selectedMonth, selectedYear } = useSelector((state) => state.common);
  const [rightTab, setRightTab] = useState(0);
  const [allComments, setAllComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [summaryData, setSummaryData] = useState([]);

  const getSheet = async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.post('/api/v1/gstr/view/sheet', {
        year: selectedYear,
        month: selectedMonth
      });
      setAllComments(res.data?.data?.all_comments);
      setSummaryData(res.data?.data?.summery);
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedMonth && activeTab === 'view_form') 
      getSheet();
  }, [selectedMonth, activeTab]);

  return (
    <div className="h-[20rem] min-w-[16rem] bg-[#fafafa] rounded-md p-3">
      <div className="mb-3">
        <span
          onClick={() => setRightTab(0)}
          className={`${
            rightTab === 0 ? 'bg-[#0d4473] text-white' : ''
          } text-xs cursor-pointer p-2 px-3 rounded-full relative`}>
          Download
          {rightTab == 0 && (
            <div className=" absolute w-0 h-0 left-10 -bottom-2 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-t-[10px] border-t-[#0d4473]"></div>
          )}
        </span>
        <span
          onClick={() => setRightTab(1)}
          className={`${
            rightTab === 1 ? 'bg-[#0d4473] text-white' : ''
          } text-xs cursor-pointer p-2 px-3 rounded-full relative`}>
          Comments{' '}
          {rightTab == 1 && (
            <div className=" absolute w-0 h-0 left-14 -bottom-2 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-t-[10px] border-t-[#0d4473]"></div>
          )}
        </span>
      </div>

      {rightTab === 0 ? (
        <ul className="list-group list-group-flush r-list-group r-list-group-icon">
        {summaryData?.map(summary => (
          <li key={summary.id} class="list-group-item">
            <a href={summary.url} target="_blank">
              <i class="las la-file-pdf pa-text-danger mx-2"></i>{summary.file_name}
            </a>
            <small>{moment(summary.updated_date).format('DD-MM-YYYY')}</small></li>
        ))}
       </ul>
      ) : (
        <div className="flex flex-col overflow-y-scroll h-[13rem] divide-y">
          {allComments?.map((comment) => (
            <p className={`hover:bg-[#f1f1f1] text-xs flex flex-col m-0 items-start gap-x-2 py-3 px-2 rounded-md cursor-pointer ${comment.status !== 4 ? 'justify-between' : ''}`}>
              <div className="flex w-full items-center gap-x-2">
                {comment.status === 4 ? <FaCircleUser size={18} color="#fcbba5 "/> : <GoCommentDiscussion size={18} color="#fcbba5 " />}
                <span>{comment.comment}</span> 
                {comment.status && (
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id={`button-tooltip-${comment.id}`}>
                        {comment.status === 1 && 'Approved'}
                        {comment.status === 2 && 'Rejected'}
                        {comment.status === 4 && 'Resolved'}
                      </Tooltip>
                    }
                    container={document.body}
                  >
                    <span id={`button-tooltip-${comment.id}`} className="ml-auto">
                      <FaCircleDot className={`${comment.status === 1 && 'text-success'} ${comment.status === 4 && 'text-success'} ${comment.status === 2 && 'text-danger'}`} />
                    </span>
                  </OverlayTrigger>
                )}
              </div>
            
              {/* Second line: Profile name */}
              <div className="mt-1 text-gray-600">
                <span className="text-xs">Added by: {comment.created_user_profile?.name}</span>
              </div>
            </p>
          ))}
        </div>
      )}
    </div>
  );
};
export default Gstr1Sidebar;
