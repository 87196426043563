import React from 'react'
import { Accordion, AccordionContext, useAccordionButton  } from 'react-bootstrap'
import moment from "moment";

const BillsSidebar = React.memo(({uploadedBills, handleDownloadZip, handleDeleteBill}) => {
  const refactorBills = uploadedBills?.filter(bills => bills?.note_files?.length !== 0 );
  return (
    <div className="gstr-sidebar">
        <h5>
            Uploaded Bills
        </h5>
        <div id="accordionUploadedBills" className="accordion pfa-sidebar-acc">
            <Accordion>
                {refactorBills?.map( (bill,index  ) => (
                    <Accordion.Item eventKey={bill.id} key={bill.id}>
                    
                        <div id={`heading-${bill.id}`} className="pfa-header">
                            <h6 className="mb-0">
                            <CustomAccordionToggle eventKey={bill.id}>
                                <span className="pf-upload-count">{bill.note_files && bill.note_files.length && bill.note_files.length}</span>{bill.notes ? bill.notes.substring(0, 15) + (bill.notes.length > 15 ? '...': '' ): 'Notes'}
                                
                            </CustomAccordionToggle>
                            <small>{moment(bill?.updated_date).format('DD-MM-YYYY')}</small>
                            </h6>
                        </div>
                    
                    <Accordion.Body className="custom-accordion-body">
                        {bill?.note_files && bill.note_files.length > 0 && (
                            <div className="pa-acc-card-body">
                                <div class="pfa-down-all"><a class="badge badge-success1" href="#" onClick={() => handleDownloadZip(bill)}>Download all <i class="las la-download"></i></a></div>
                                <ul className="list-group list-group-flush pfa-down-list">
                                {bill.note_files.map((file, index) => (
                                            <li className="list-group-item "  key={file.id}>
                                              <a href={file.url} target="_blank">{file.file_name} <span className="pfa-down-list-icon"><i className="las la-download"></i></span></a>
                                              <a href="#" onClick={() => handleDeleteBill(file.id)}> <span className="pfa-down-list-icon"><i className="las la-trash"></i></span></a>
                                            </li>
                                        ))}
                                </ul>
                                {bill.notes && <p className="pa-acc-notes alert alert-success">{bill.notes}</p>}
                            </div>
                        )}
                    </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </div> 
    </div>
  )
})

const CustomAccordionToggle = ({ children, eventKey, callback }) => {
    const { activeEventKey } = React.useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey, callback);
  
    return (
      <a
        href="#"
        onClick={e => {
          e.preventDefault();
          decoratedOnClick();
        }}
        className={`d-block position-relative text-dark custom-accordion-toggle collapsible-link ${activeEventKey === eventKey ? 'active' : ''}`}
      >
        {children}
      </a>
    );
  };

export default BillsSidebar
