import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from '../../services/instance'
import { GET_FACILITATION_NAME } from '../../constants/services'

export const getFacilitationName = createAsyncThunk(
  "facilitation/getFacilitationName",
  async ({ page = 1, perpage = 25 }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(GET_FACILITATION_NAME, {
        page,
        perpage, 
      });
      return response.data; 
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);

const facilitationSlice = createSlice({
  name: "facilitation",
  initialState: {
    facilitationData: [], 
    loading: true,
    error: null,
    total_count: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFacilitationName.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getFacilitationName.fulfilled, (state, action) => {
      state.facilitationData = action.payload.data?.list || [];
      state.loading = false;
      state.total_count =  action.payload.data?.total_count;
    });
    builder.addCase(getFacilitationName.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export default facilitationSlice.reducer;