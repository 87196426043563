import React, { useEffect, useState } from "react";
import { Navbar, Nav, Collapse, ListGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Logo from '../../assets/images/logo.png'
import WhiteLogo from '../../assets/images/logo-white.png'
import { useSelector, useDispatch } from "react-redux";
import { setNavOpen } from "../../slices/common";
import { PAGE_PERMISSIONS } from '../../constants/staff';
import useCheckAuthorization from "../../hooks/checkAuthorization";

const useWindowResize = () => {
    useEffect(() => {
      const handleResize = () => {
        // Check window width and add/remove class
        if (window.innerWidth < 1298) {
          document.body.classList.add('small-screen');
        } else {
          document.body.classList.remove('small-screen');
        }
      };
  
      // Call handleResize once to set the initial class based on window size
      handleResize();
  
      // Attach the event listener
      window.addEventListener('resize', handleResize);
  
      // Cleanup the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []); // Empty dependency array ensures this effect runs only once
  };

const Sidebar = ({closeSidebar}) => {
  const [collapseState, setCollapseState] = useState({
    manageFormMenu: !location.pathname.includes('/gst-forms/gstr3b') && !location.pathname.includes('/gst-forms/gstr1'),
    reportsMenu: true,
  });
  const navOpen = useSelector((state) => state.common.navOpen);
  const [closeButton, setCloseButton] = useState(false)
  const dispatch = useDispatch();
  const hasPermission = useCheckAuthorization(PAGE_PERMISSIONS.admin)
  useWindowResize();

  const toggleSidebar = (e) => {
    if((document.body.classList.contains('small-screen') && e.currentTarget.classList.contains('nav-link')) || (!document.body.classList.contains('small-screen') && e.currentTarget.classList.contains('wrapper-menu')))
      document.body.classList.toggle('sidebar-main');
  }
  const toggleCollapse = (menu) => {
    setCollapseState((prevState) => ({
        ...prevState,
        [menu]: !prevState[menu]
    }));
  };
  /*useEffect(() => {
    setCollapseState((prevState) => ({
      ...prevState,
      manageFormMenu: false
    }));
  }, [])*/
 
  return (
    <div className="iq-sidebar sidebar-default">
      {/* Sidebar Logo */}
      <Navbar className="iq-sidebar-logo d-flex align-items-center justify-content-between">
        <Nav.Link as={NavLink} to="/" className="header-logo">
            <img src={Logo} className="img-fluid rounded-normal light-logo" alt="logo" />
            <img src={WhiteLogo} className="mg-fluid rounded-normal darkmode-logo d-none" alt="logo" />
        </Nav.Link>
        <div className="iq-menu-bt-sidebar" >
          <i className="las la-bars wrapper-menu" onClick={toggleSidebar}></i>
        </div>
      </Navbar>

      {/* Sidebar Navigation */}
      <div className="data-scrollbar" data-scroll="1">
        <div className="iq-sidebar-menu">
            <ul id="iq-sidebar-toggle" className="iq-menu">
                <li>
                    <Nav.Link as={NavLink} to="/dashboard" onClick={toggleSidebar}><i className="las la-home iq-arrow-left"></i><span>Dashboard</span></Nav.Link>
                </li>
                <li>
                    <Nav.Link as={NavLink} to="/gst-forms" onClick={(e) => {toggleCollapse("manageFormMenu"); toggleSidebar(e)}} className={collapseState.manageFormMenu ? "collapsed" : ""} data-toggle="collapse" aria-expanded={!collapseState.manageFormMenu}>
                      <i className="las la-torah iq-arrow-left"></i><span>Manage Form</span>
                      {/*<i className="las la-angle-right iq-arrow-right arrow-active"></i>
                      <i className="las la-angle-down iq-arrow-right arrow-hover"></i> */}
                    </Nav.Link>
                    <ul id="ManageForm" className={`iq-submenu ${!collapseState.manageFormMenu ? "show" : "collapse"}`} data-parent="#iq-sidebar-toggle">
                        <li>
                            <Nav.Link as={NavLink} to="/gst-forms/gstr3b">
                                <i className="las la-sign-in-alt"></i><span>GSTR - 3B</span>
                            </Nav.Link>
                        </li>
                        <li>
                            <Nav.Link as={NavLink} to="/gst-forms/gstr1">
                                <i className="las la-sign-in-alt"></i><span>GSTR 1</span>
                            </Nav.Link>
                        </li>
                    </ul>
                </li>
                {hasPermission && 
                  <li>
                    <Nav.Link as={NavLink} to="/manage-staff" onClick={toggleSidebar}><i className="las la-users iq-arrow-left"></i><span>Manage Staff</span></Nav.Link>
                  </li>
                } 
                <li>
                    <Nav.Link eventKey="link-2" onClick={toggleSidebar}> <i className="las la-comments iq-arrow-left"></i><span>Discussion</span></Nav.Link>
                </li>
                <li>
                    <Nav.Link eventKey="link-2" onClick={toggleSidebar}><i className="las la-project-diagram iq-arrow-left"></i><span>Activities</span></Nav.Link>
                </li>
                <li>
                    <Nav.Link eventKey="link-2" onClick={toggleSidebar}><i className="las la-cloud-upload-alt iq-arrow-left"></i><span>Upload Shipping Bills</span></Nav.Link>
                </li>
                <li>
                    <Nav.Link as={NavLink} to="/manage-profile" onClick={toggleSidebar}><i className="las la-user-cog iq-arrow-left"></i><span>Manage Profile</span></Nav.Link>
                </li>
            </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;