import React from 'react'

const Loader = ({content}) => {
  return (
    <div className="loading-overlay">
        <div className="loading-message">{content}</div>
    </div>
  )
}

export default Loader
