import React from "react";
import { Button, Col, Row, Table } from "react-bootstrap";

const RefundList = ({ refundData }) => {
  
  return (
    refundData.length === 0 ? <Col xl={12} ><h4 className="text-center mt-4">No Refunds Found</h4> </Col> :(
      <Table
        striped
        bordered
        hover
        className="mt-2"
      >
        <thead>
          <tr>
            <th>ARN Number</th>
            <th>ARN Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {refundData.map((data) => (
            <tr key={data.id}>
              <td>{data.arn_no}</td>
              <td>{data.arn_date}</td>
              <td>{data.status.name}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  );
};

export default RefundList;
