export const SIGN_IN = '/api/v1/user/login';
export const PROFILE = '/api/v1/user/profile';
export const GET_FORM_DATA = '/api/v1/gstr/view/sheet';
export const GET_CELL_DATA = `/api/v1/gstr/fetch/comments`;
export const ADD_COMMENT = '/api/v1/gstr/add/comment';
export const UPLOAD_FILES = '/api/v1/gstr/uploads';
export const GSTR3B_GET_FORM_DATA = '/api/v1/gstr3b/view/sheet';
export const GSTR3B_GET_CELL_DATA = `/api/v1/gstr3b/fetch/comments`;
export const GSTR3B_ADD_COMMENT = '/api/v1/gstr3b/add/comment';
export const GSTR3B_UPLOAD_FILES = '/api/v1/gstr3b/uploads';
export const GET_REFUND_FORM_DATA = '/api/v1/refund/view/sheet';
export const GET_REFUND_CELL_DATA = `/api/v1/refund/fetch/comments`;
export const ADD_REFUND_COMMENT = '/api/v1/refund/add/comment';
export const UPLOAD_REFUND_FILES = '/api/v1/refund/upload/files';
export const GET_REFUND_DATA = '/api/v1/client/refund/list/arn';
export const UPDATE_PROFILE = '/api/v1/user/manage/profile';
export const UPDATE_PASSWORD = '/api/v1/user/password/update';
export const HANDLE_REFUND_SHEET_APPROVAL = '/api/v1/refund/approve';
export const HANDLE_MANAGER_REFUND_SHEET_APPROVAL = '/api/v1/refund/manager/approve';
export const HANDLE_GSTR_SHEET_APPROVAL = '/api/v1/gstr/approve';
export const HANDLE_MANAGER_GSTR_SHEET_APPROVAL = '/api/v1/gstr/manager/approve';
export const HANDLE_GSTR3B_SHEET_APPROVAL = '/api/v1/gstr3b/approve';
export const HANDLE_MANAGER_GSTR3B_SHEET_APPROVAL = '/api/v1/gstr3b/manager/approve';
export const CREATE_REFUND_PERIOD = '/api/v1/refund/create/folder';
export const LIST_ROLES = '/api/v1/user/role/list';
export const CREATE_STAFF = '/api/v1/user/create/staff';
export const GET_STAFF_LIST = '/api/v1/user/staff/list';
export const STAFF_STATUS_UPDATE = '/api/v1/user/change/status/staff';
export const GET_STAFF_INFO = '/api/v1/user/view/staff';
export const UPDATE_STAFF= 'api/v1/user/update/staff';
export const LIST_NOTIFICATIONS= 'api/v1/user/staff/notifications';

