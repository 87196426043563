import React, { useEffect } from 'react';
import Base from '../common/Base';
import { Outlet, useNavigate } from "react-router-dom";
import useCheckAuthorization from '../../hooks/checkAuthorization';
import { PAGE_PERMISSIONS } from '../../constants/staff';

const StaffModule = () => {
  const navigate = useNavigate();
  const hasPermission = useCheckAuthorization(PAGE_PERMISSIONS.admin);
  useEffect(() => {
    if (!hasPermission) {
        navigate('/authorization-error');
    }
  }, [hasPermission]); 

  return (
    <Base>
        <Outlet/>
    </Base>
  )
}

export default StaffModule;
