import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from '../../services/instance';
import { GET_LITIGATION_LIST } from '../../constants/services';

export const getLitigations = createAsyncThunk(
  "litigation/getLitigations",
  async ({ page = 1, perpage = 25 }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(GET_LITIGATION_LIST,{
        page,
        perpage, 
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);

const litigationSlice = createSlice({
  name: "litigation",
  initialState: {
    litigationData: [], 
    loading: true, 
    error: null,
    total_count:0
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLitigations.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.total_count = 0;
    });
    builder.addCase(getLitigations.fulfilled, (state, action) => {
      state.litigationData = action.payload.data?.list || []; 
      state.loading = false;
      state.total_count = action.payload.data.total_count;
    });
    builder.addCase(getLitigations.rejected, (state, action) => {
      state.error = action.payload; 
      state.loading = false;
    });
  },
});

export default litigationSlice.reducer;