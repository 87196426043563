import React, { useState, useEffect } from 'react'
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getRoles } from '../../slices/staff';
import { ROLE_PERMISSIONS, STAFF_SCHEMA } from '../../constants/staff';

const StaffForm = ({submitFunc, isUpdate = false, staff}) => {
    const dispatch = useDispatch();
    const { staffFormData, roles } = useSelector(state => state.staff);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        name: staffFormData.name,
        email: staffFormData.email,
        password: staffFormData.password,
        role: staffFormData.role,
        mobile: staffFormData.mobile,
    });

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (typeof submitFunc === 'function') {
            let values = {
                name: null,
                email: null,
                mobile: '', 
                role: null
            }

            if (!isUpdate) {
                values['password'] = null
                values['r_password'] = null
            }

            if (e.target) {
                values = {
                    name: e.target['name'].value,
                    email: e.target['email'].value,
                    mobile: e.target['mobile'].value, 
                    role: e.target['role'].value
                }

                if (!isUpdate) {
                    values['password'] = e.target['password'].value
                    values['r_password'] = e.target['r_password'].value
                }
            }
            try {
               
                const data = await STAFF_SCHEMA(isUpdate).validate(values, { abortEarly: false });
                setErrors({});
                submitFunc(data)
            } catch (err) {
                const errData = {}
                err.inner.forEach(e => {
                    errData[e.path] = e.message
                    
                });
                setErrors(errData);
                
            }
             window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
             });
      
          //  
        } 
    }

    const handleChange = (e) => {
        const role_id = e.target.value;
         const user_role = roles.filter(role => role.id == role_id)
         if (Array.isArray(user_role) && user_role.length) {
            setSelectedPermissions(_.map(user_role[0].permissions, 'codename'))
         }
         setFormData({...formData, role: role_id})
    }

    //const roles = useSelector(state => state.user.roles)
    useEffect(() => {
        if(isUpdate && !!staff.name) {
            const {name, email, phone, role} = staff
            setFormData({name, email, mobile:phone, role: role.id})
            setSelectedPermissions(_.map(role.permissions, 'codename'))
        }
    },[isUpdate, staff])

    useEffect(() => {
        dispatch(getRoles())
    }, []);
    
    useEffect(() => {
        if (Array.isArray(roles) && roles.length) {
            setSelectedPermissions(_.map(roles[1].permissions, 'codename'))
        }
    }, [roles])

    return (
    <div className="new-staff-info">
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col md={6} className="mb-2">
                    <Form.Group controlId="name">
                        <Form.Label htmlFor="name">Name:</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                type="text"
                                id="name"
                                name="name"
                                isInvalid={!!errors.name}
                                defaultValue={isUpdate? formData.name : ''}

                            />
                            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Col>

                <Col md={6} className="mb-2">
                    <Form.Group controlId="mobileNumber">
                        <Form.Label>Mobile Number:</Form.Label>
                        <Form.Control
                            type="text"
                            id="mobileNumber"
                            placeholder="Mobile Number"
                            name="mobile"
                            defaultValue={isUpdate ? formData.mobile : ''}
                            
                        />
                    </Form.Group>
                </Col>

                <Col md={6} className="mb-2">
                    <Form.Group controlId="email">
                        <Form.Label>Email:</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                type="text"
                                id="email"
                                name="email"
                                isInvalid={!!errors.email}
                                disabled={isUpdate}
                                defaultValue={isUpdate ? formData.email : ''}
                            />
                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Col>
                {!isUpdate && (<><Col md={6} className="mb-2">
                    <Form.Label htmlFor="password">Password:</Form.Label>
                    <InputGroup hasValidation>
                    <Form.Control
                        type="password"
                        id="password"
                        name="password"
                        isInvalid={!!errors.password}
                    />
                    <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                    </InputGroup>
                </Col>
                <Col md={6} className="mt-2">
                    <Form.Label htmlFor="r_password">Repeat Password:</Form.Label>
                    <InputGroup>
                    <Form.Control
                        type="password"
                        id="r_password"
                        name="r_password"isInvalid={!!errors.r_password}
                    />
                        <Form.Control.Feedback type="invalid">{errors.r_password}</Form.Control.Feedback>
                    </InputGroup>
                </Col></>)}
            </Row>
            <hr/>
            <Row>
                <Col 
                    xs={12}
                    md={12}
                    lg={12}
                    xl={12}
                >
                <div class="header-title">
                    <Card.Title as="h5">Roles & Permissions</Card.Title>
                </div>
                </Col>
                <Col
                    xs={12}
                    md={12}
                    lg={12}
                    xl={12}
                >
                        <Form.Label htmlFor="role">User Role:</Form.Label>
                        <Form.Select
                            
                            name="role" 
                            onChange={handleChange} 
                            value={formData.role}
                            >
                            {
                                roles.map(role => {
                                    return  <option value={role.id}>{role.name}</option>
                                })
                            }
                        </Form.Select>
                </Col>
                <Col className="mt-4">
                    <Row>
                        {Object.keys(ROLE_PERMISSIONS). map(role_key => {
                            return <Col md={4}>
                            <Form.Check 
                            type="checkbox" 
                            label={ROLE_PERMISSIONS[role_key]} 
                            disabled 
                            checked={selectedPermissions.includes(role_key)}
                            />
                        </Col>
                        })}
                    </Row>
                </Col>
            </Row>
            <Button type="submit" variant="primary" className="btn btn-primary mt-3">{isUpdate ? 'Edit': 'Add'} Staff</Button>
        </Form>
    </div>
    )
}

export default StaffForm
