import Base from '../../common/Base';
import { useEffect, useState } from 'react';
import { Card, Tab, Nav, Row, Col, Form} from 'react-bootstrap';
import RefundSidebar from '../sidebar/RefundSidebar';
import GstrUploadBills from './../tables/GstrUploadBills';
import uploading_image from '../../../assets/images/up-loading.png';
import { uploadFiles, getRefundData, clearMessages, handleSheetApproval, handleSheetApprovalManager, createRefundPeriod  } from '../../../slices/forms/refund';
import { clearNotification } from '../../../slices/notification';
import instance from '../../../services/instance';
import { useDispatch, useSelector } from 'react-redux';
import RefundList from './RefundList';
import BillsSidebar from '../sidebar/BillsSidebar';
import axiosInstance from '../../../services/instance';
import ChatPopup from '../../common/ChatPopup';
import ViewSheetSummary from '../ViewSheetSummary';
import toast from "react-hot-toast";
import Loader from '../../common/Loader';
import { CRUD_PERMISSIONS } from '../../../constants/staff';
import useCheckAuthorization from '../../../hooks/checkAuthorization';

const Refunds = () => {
  const [key, setKey] = useState('view_form');
  const [refundData, setRefundData] = useState([]);
  const [uploadedBills, setUploadedBills] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sheetData, setSheetData] = useState(null);
  const [sheetSummaryData, setSheetSummaryData] = useState(null);
  const dispatch = useDispatch();
  const changeTab = (k) => {
    setKey(k);
  };
  const { uploadFilesSuccess, billsUploading, uploadFilesData, handleSheetApprovalState, createRefundPeriodState } = useSelector((state) => state.refund);
  const { isNotificationRoute, notification } = useSelector((state) => state.notification);
  //const { selectedMonth, selectedYear } = useSelector((state) => state.common);
   /*Period section */
   const [periodId, setPeriodId] = useState("");
   const [periodData, setPeriodData] = useState([]);
   const [periodErrors, setPeriodErrors] = useState({
     periodName: "",
     startYearMonth: "",
     endYearMonth: "",
     errorResponse:""
   });
   const isManager = useCheckAuthorization(CRUD_PERMISSIONS.sheet.client_manager_role);
  

  const uploadFileData = (files, section_type, notes) => {
    dispatch(uploadFiles({ folder_id: periodId, files, section_type, notes }));
  };

  
  const handleSheetSubmit = (sheetId, status) => {
    setLoading(true);
    dispatch(handleSheetApproval({  folder_id: periodId, approve:status, isManager, sheetId  }));
  };

  const handleSheetSubmitManager = (sheetId, status ) => {
    setLoading(true);
    dispatch(handleSheetApprovalManager({  folder_id: periodId, approve:status, isManager, sheetId}));
  };


  const normalizeData = async (data) => {
    return data.map((item) => ({
      ...item,
      note_files: item.refund_note_files
    }));
  };

  const getUploadedBills = async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.post('/api/v1/refund/lists', {
        folder_id: periodId,
        perPage: 10,
        page: 1
      });
      const formattedData = await normalizeData(res.data.data.list);
      setUploadedBills(formattedData);
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  };
  
  const getSheet = async (period_id) => {
    try {
      setLoading(true);
      const res = await axiosInstance.post('/api/v1/refund/view/sheet', {
        folder_id: period_id
      });
      if(res.data.data)
        setSheetSummaryData(res.data?.data);
      if(res.data?.data?.sheet !==  null){
        setSheetData(res.data?.data?.sheet);
      }else{
        setSheetData(null);
      }
      
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  };
  const clearFormUpload = async () => {
    dispatch(clearMessages(true));
    await getUploadedBills();
  };
  if (uploadFilesSuccess) {
    clearFormUpload();
  }

  const getPeriodData = async() => {
    try {
      const res = await instance.get('/api/v1/refund/folder/list/1/10');
      setPeriodData(res.data.data.list);
    } catch (error) {
      console.error('error', error);
    }
  }
  if (handleSheetApprovalState.success || handleSheetApprovalState.error || createRefundPeriodState.success) {
    dispatch(clearMessages(true));
    getSheet(periodId);
  }

  if(createRefundPeriodState.error){
    dispatch(clearMessages(true));
    toast(createRefundPeriodState.data?.message);
    
  }

 

  const getRefundData = async () => {
    try {
      const res = await instance.post('/api/v1/refund/arn/list', {
        folder_id: periodId
      });
      setRefundData(res.data.data.list);
    } catch (error) {
      console.error('error', error);
    }
  };
 
  

  
  const handlePeriodSelect = async (period_id) => {
    setPeriodId(period_id);
    if(period_id){
      await getSheet(period_id);
    }else{
      setSheetData(null);
      setSheetSummaryData(null);
    }
  }

  const handleDownloadZip = async (item) => {
    try {
      const promise = axiosInstance.post(
        `/api/v1/refund/download/zip`,
        {
          note_id: item.id,
        }, {
          responseType: 'blob', // Important! Tells axios to handle the response as a blob
        }
      );
      const response = await toast.promise(promise, {
        loading: "Downloading...",
        success: <b>Downloaded!</b>,
        error: <b>Downloading Failed.</b>,
      });
      // Create a URL for the blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      
      // Create a link element
      const link = document.createElement('a');
      link.href = url;
      const fileName = item.notes.replace(/ /g, "_");
      link.setAttribute('download', `${fileName}.zip`); // Specify the filename

      // Append to the document and click the link to trigger download
      document.body.appendChild(link);
      link.click();

      // Cleanup
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("error", error);
    } finally {
    }
  }
  
  const handleDeleteBill = async (billId) => {
    try {
      setLoading(true);
      const promise = axiosInstance.post('/api/v1/refund/delete/file', {
        file_id: billId,
      });
      const response = await toast.promise(promise, {
        loading: "Deleting...",
        success: <b>Deleted!</b>,
        error: <b>Deleting Failed.</b>,
      });
      getUploadedBills();
      
      
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (key === 'upload_bills' && periodId) getUploadedBills();

    if (key === 'refunds' && periodId) getRefundData();
    if(key === 'view_form')
      getPeriodData();
    if (key === 'view_form'&& periodId) getSheet(periodId);
  }, [key, periodId, periodErrors.errorResponse]);

  useEffect(() => {
    if(isNotificationRoute){
      handlePeriodSelect(notification?.params?.period);
      /*if(notification?.parameters?.selected_tab === 1){
        setKey('view_form');
      }else if(notification?.parameters?.selected_tab === 2){
        setKey('upload_bills');
      }else if(notification?.parameters?.selected_tab === 3){
        setKey('refunds');
      }*/
      dispatch(clearNotification());
      
    }
  }, [isNotificationRoute])

  return (
    <Base header="refunds" headerTitle={'Refunds'}>
      <Card>
        <Card.Body>
          {/* Period selection */}
          <Row className="pb-10">
            {/* First Column */}
            <Col md={2}>
              <Form.Group controlId="periodSelection">
                <Form.Label>Select Period</Form.Label>
                <Form.Control
                  as="select"
                  value={periodId}
                  onChange={(e) => handlePeriodSelect(e.target.value)}
                >
                  <option value="">Select a period</option>
                  {periodData?.map(periodItem => 
                    <option value={periodItem.id} key={periodItem.id}>{`${periodItem.prefix ? periodItem.prefix + ' - ' : ''}${periodItem.folder_name}`}</option>
                  )}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          {/* Period selection */}
          {!periodId ? <h4 className="text-center mt-4">Please Select a Period</h4>: 
          <>
          <hr />
          <Tab.Container id="left-tabs-example" activeKey={key} onSelect={changeTab}>
            <Nav variant="tabs" as="ul" className="pa-nav-tabs-1 gstr-tab">
              <Nav.Item as="li">
                <Nav.Link eventKey="view_form">View Form</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link eventKey="upload_bills">Upload Bills</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link eventKey="refunds">Refund Status</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="view_form">
                <Row style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                  <Col xl={9}>
                  { !sheetData  ? <h4 className="text-center mt-4">No Summary Uploaded For the period !</h4> : <ViewSheetSummary sheetSummaryData={sheetSummaryData} handleSheetSubmit={handleSheetSubmit} handleSheetSubmitManager={handleSheetSubmitManager} sheetType="refunds" getSheet={getSheet} periodId={periodId}/> }
                  </Col>
                  <Col xl={3}>
                    <RefundSidebar activeTab={key} periodId={periodId} />
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="upload_bills">
                <Row>
                  <Col xl={8}>
                    <Tab.Container defaultActiveKey="shipping_bills">
                      <Nav
                        variant="tabs"
                        as="ul"
                        className="pa-nav-tabs-2 pa-nav-icon-tabs">
                        <Nav.Item as="li">
                          <Nav.Link eventKey="shipping_bills">
                            Shipping Bills
                            <span className="pa-check-mark">
                              <i className="fas fa-check-circle"></i>
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link eventKey="sales_register">
                            Sales register
                            <span className="pa-check-mark">
                              <i className="fas fa-check-circle"></i>
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link eventKey="invoices">
                            Invoices
                            <span className="pa-check-mark">
                              <i className="fas fa-check-circle"></i>
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link eventKey="others">
                            Others
                            <span className="pa-check-mark">
                              <i className="fas fa-check-circle"></i>
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        {[
                          { key: 'shipping_bills', label: 'Upload Shipping Bill', section: 1 },
                          { key: 'sales_register', label: 'Sales Register', section: 2 },
                          { key: 'invoices', label: 'Invoices', section: 3 },
                          { key: 'others', label: 'Others', section: 4 }
                        ].map((tab) => (
                          <Tab.Pane eventKey={tab.key} key={tab.key}>
                            <h4
                              className="jl-heading mt-0 mb-10 inline card-title bold-font"
                              id="Filechange">
                              <span className="pa-upload-icon">
                                <img src={uploading_image} alt="" />
                              </span>
                              {tab.label}
                            </h4>
                            {billsUploading ? <Loader content="Uploading File! Please wait. Do not refresh or press back button" /> : <GstrUploadBills
                                handleUploadFile={(fileData, notes) =>
                                  uploadFileData(fileData, tab.section, notes)
                                }
                              />}
                          </Tab.Pane>
                        ))}
                      </Tab.Content>
                    </Tab.Container>
                  </Col>
                  <Col xl={4}>
                    <BillsSidebar uploadedBills={uploadedBills} handleDownloadZip={handleDownloadZip} handleDeleteBill={handleDeleteBill}/>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="refunds">
                <Row style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                  <RefundList refundData={refundData} />
                </Row>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
          </>
          }
        </Card.Body>
      </Card>
      <ChatPopup chat_type="REFUND" />
    </Base>
  );
};
export default Refunds;
